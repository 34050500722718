<template>
    <div class="goods-details">
        <Header></Header>
        <div class="item">
            <div class="title">{{goodsData.goodsName}}</div>
            <div class="spec">
                <span>规格：</span>
                <span v-for="(item, index) in goodsData.goodsSpecifications" :key="index">{{item.specification}}：{{item.value}}-{{item.valueEnd}}{{item.unit}}</span>
            </div>
            <van-swipe class="goods-swipe" :height="'20rem'" :autoplay="3000">
                <van-swipe-item class="swipe-item" v-for="(image, index) in goodsData.gallery" :key="index">
                    <img v-lazy="image" />
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="bottom-ctl">
            <div class="count">数量：{{goodsData.number}}</div>
            <div class="price">价格：<span>{{goodsData.price}}</span>元</div>
        </div>
    </div>
</template>

<script>
import { goodsDetails } from '@/api/goodsShare'
import Header from '@/components/Header.vue'
export default {
    name: 'GoodsDetails',
    components: {
        Header
    },
    data() {
        return {
            goodsData: {}
        }
    },
    mounted() {
      // console.log(this.$route.query.gid)
      goodsDetails({orderGoodsId: this.$route.query.gid}).then(res=>{
        this.goodsData = res.data.data
      })
    }
}
</script>

<style lang="less">
.goods-details {
  .item {
    padding: 1.2rem;
    // border-bottom: 1px solid #f0f0f0;
    .title {
      font-size: 1rem;
      font-weight: bold;
    }
    .spec {
      font-size: .9rem;
      color: #869099;
      padding: .5rem 0;
    }
  }
  .goods-swipe {
      margin-top: 2rem;
      .swipe-item {
          height: 20rem;
      }
      img {
          max-height: 20rem;
          width: auto;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
      }
  }
  .bottom-ctl {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .count {
      font-size: .9rem;
    }
    .price {
      font-size: 1.1rem;
      font-weight: bold;
      margin: 0 1.2rem;
      span {
        color: orangered;
      }
    }
    span {
      font-weight: bold;
    }
  }
}
</style>